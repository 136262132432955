import "./Product.scss";
import React from 'react';
import logo from '../../../assets/products/pro1.jpg';

const Product = () => {
    return (
        <>
            <div className="card">
                <img src={logo} className='product--image' alt="product images" />
                <h2>CHARCOAL FACE WASH </h2>
                <p className="text">DAPPER DADDY ACTIVATED CHARCOAL FACE WASH for men 100ml </p>
                <p className='price'>Rs 400</p>
                <p><button>Add to Cart</button></p>
            </div>
        </>

    )
}

export default Product
