import React from 'react';
import "./Categories.scss";
import logo from '../../../assets/category/face.jpg';



const Categories = () => {
    return (
        <>
            <div className="cards">
                <img src={logo} className='product--images' alt="product images" />
                <h2>Hair</h2>
            </div>
        </>
    )
}

export default Categories