import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Category.scss";
import Categories from './Categories/Categories';



const Category = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
    return (
        <>
            <div className="products-containers">
                <div className="sec-headings">CATEGORIES</div>
                <Carousel responsive={responsive} infinite={true} >

                    <Categories />
                    <Categories />
                    <Categories />
                    <Categories />

                </Carousel>
            </div >
        </>
    )
}

export default Category;

