import "./Reviewss.scss";
import React from 'react';
import logo from '../../../assets/category/hairs.jpg';

const Reviewss = () => {
    return (
        <>
            <div className="Wrapper">
                <div className="Cards">
                    <div className="Card">
                        <img src={logo} alt="" />
                        <p className="Message">Lorem Ipsum is a dummy text used in the graphic and typesetting industry as a placeholder for actual content.</p>

                        <h2 className="Title">Jay Prakash jha</h2>
                        <h4 className="Desc">Software Developer</h4>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Reviewss;
