import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ProBanner.scss";
import img1 from "../../assets/ProBanner/product-banner-de-tan.jpg";
import img2 from "../../assets/ProBanner/product-banner-spider-wax.jpg";




const ProBanner = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
    return (
        <>
            <div className="products-containerss">
                <Carousel responsive={responsive} infinite={true} >

                    <div className="cardss">
                        <img src={img1} className='product--imagess' alt="product images" />
                    </div>
                    <div className="cardss">
                        <img src={img2} className='product--imagess' alt="product images" />
                    </div>

                </Carousel>
            </div >
        </>
    )
}

export default ProBanner;

