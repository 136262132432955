export const slidesData = [
    {
        id: "001",
        img: "https://dappp-reactpj.pages.dev/static/media/home1.d90c2bb7d16e37d4418a.jpg",
        title: "Finde Premium Products",
        cta: "SHOP NOW",
    },
    {
        id: "002",
        img: "https://dappp-reactpj.pages.dev/static/media/home2.0c590480535864256dc5.jpg",
        title: "Get all the Accessories you need",
        cta: "SHOP NOW",
    },
    {
        id: "003",
        img: "https://dappp-reactpj.pages.dev/static/media/home1.d90c2bb7d16e37d4418a.jpg",
        title: "Wide  range of colors",
        cta: "SHOP NOW",
    },
];
