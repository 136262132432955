import React from 'react';
import "./Icons.scss";
import icon from "../../assets/icons/1.png"
import icons from "../../assets/icons/2.png"
import iconss from "../../assets/icons/3.png"

import { AiOutlineSolution } from "react-icons/ai";

const Icons = () => {
    return (
        <div className="main-headers">
            <div className="header-contents">
                <div className="center">
                    <img src={icon} alt="" />
                    <img src={icons} alt="" />
                    <img src={iconss} alt="" />
                    
                    
                
                </div>
            </div>
        </div>
    )
}

export default Icons