import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Reviews.scss";
import Reviewss from './Reviewss/Reviewss';


const Reviews = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            <div className="products-containerr">
                <div className="sec-headingg">OUR CUSTOMER REVIEWS</div>
                <Carousel responsive={responsive} arrows={false} >
                    <Reviewss />
                    <Reviewss />
                    <Reviewss />
                    <Reviewss />
                    <Reviewss />
                    <Reviewss />
                    
                </Carousel>
            </div >
        </>
    )
}

export default Reviews;

