import Banner from "./Banner/Banner";
import Category from "../../components/Category/Category";
import Icons from "../Icons/Icons";
import { slidesData } from "../../data/slidesData";
import "./Home.scss";
import Products from "../Products/Products";
import AboutUs from "../Aboutus/AboutUs";
import ProBanner from "../ProBanner/ProBanner";
import Reviews from "../Reviews/Reviews";

const Home = () => {
    return (
        <div >
            <Banner slides={slidesData} />
            <Icons />
            <div className="main-content">
                <div className="layout">
                    <Products />
                </div>
            </div>
            <AboutUs />
            <div className="main-content">
                <div className="layout">
                    <Category />
                    <ProBanner />
                </div>
            </div>
            <Reviews />
        </div>
    )
};

export default Home;
